.lang{
   font-size: 14px;
   display: flex;
   flex-flow: row wrap;
   justify-content: space-around;
   align-content: center;
   align-items: center;
   max-width: 115px;
}

.flag{
   width: 25px;
   padding-top: 7px;
}

.el{
   padding: 0 5px 0 5px;
}

.vl{
   border-left: 1px solid;
   height: 40px;
   margin-left: 5px;
}

.flex_row_lang{
   display: flex;
   flex-flow: row wrap;
   justify-content: center;
   align-content: center;
   align-items: center;
}