*{
   font-family: "Titillium Web",Arial,sans-serif;
}

.m_form{
   min-height: 100vh;
   background-color: #F5F5F5;
   display: flex;
   flex-flow: column wrap;
   justify-content: flex-start;
   align-content: center;
   align-items: center;
}

.Cp3MReg{
   width: 500px;
}

.labelcss{
   font-size: 13px;
   color: rgba(128, 128, 128, 1);
   font-weight: bold;
   text-align: left;
   display: inline-block;
}

.inputcss{
   font-size: 14px;
   color: rgba(128, 128, 128, 1);
   border: 1px #DEDFE0 solid;
   width: 370px;
   height: 30px;
   line-height: 30px;
}

.inputcss::placeholder{
   color: rgba(128, 128, 128, 0.45);
}

.textInput{
   padding-bottom: 10px;
}

.logo{
   width: 150px;
   padding-left: 20px;
}
.first_block{
   display: flex;
   flex-flow: row wrap;
   justify-content: space-between;
   align-content: center;
   align-items: center;
   width: 100%;
}
.langMReg{
   padding-right: 20px;
}

.twoblocksMReg{
   min-width: 100%;
   padding-top: 20px;
   display: flex;
   flex-flow: row wrap;
   justify-content: space-around;
   align-content: center;
   align-items: center;
}

.buttonsMReg{
   display: flex;
   flex-flow: row wrap;
   justify-content: center;
   align-content: center;
   align-items: center; 
   align-self: flex-end;
   padding-top: 30px;
}

.coupleAddr{
   display: flex;
   flex-flow: row wrap;
   justify-content: space-between;
   align-content: center;
   align-items: center;
   width: 372px;
}

.coupleAddr .inputcss{
   width: 170px;
   align-self: flex-start;
}

.buttonMReg{
   margin-right: 30px;
}

.columnsMReg{
   width: 800px;
   display: flex;
   flex-flow: row wrap;
   justify-content: space-between;
   align-content: space-around;
   align-items: center;
}

.formMReg{
   min-width: 800px;
}

.formTitleMReg{
   font-weight: bold;
   font-size: 22px;
   color: #AEAEAE;
   padding-bottom: 20px;
}

.infoLogo{
   margin-bottom: -1px;
   margin-left: 5px;
}

.fieldErrorMReg{
   border-color: rgb(185, 1, 1);
}

.fieldErrorMReg::placeholder{
   color: rgb(185, 1, 1);
}

.tipMReg{
   color: rgba(128, 128, 128, 1);
   font-size: 11px;
}

.cpN2_descr{
   font-weight: 300;
   font-size: 18px;
   color: #002445;
}

.cpN2_descr_title{
   font-weight: 600;
}

.cpN2_descr div{
   padding-left: 7px;
}


@media only screen and (max-width: 1345px) {
   .parteDestra{
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-content: center;
      align-items: center;
      min-width: 100%;
   }
   .parteDestra{
      padding-top: 30px;
      padding-bottom: 30px;
   }
   @media only screen and (max-width: 820px) {
      .columnsMReg{
         width: 400px;
      }
      .formMReg{
         display: flex;
         flex-flow: column wrap;
         justify-content: center;
         align-content: center;
         align-items: center;
         min-width: fit-content;
      }
      .buttonsMReg{
         align-self: center;
      }
      .columnsMReg{
         justify-content: center;
      }
      
      @media only screen and (max-width: 505px) {
         .columnsMReg{
            width: fit-content;
         }
         .Cp3MReg{
            width: 300px;
         }
         @media only screen and (max-width: 425px) {
            .columnsMReg{
               width: fit-content;
               justify-content: center;
            }
            .inputcss,.coupleAddr{
               width: 345px;
            }
            .coupleAddr{
               flex-flow: column wrap;
               justify-content: center;
            }
            .coupleAddr div,.coupleAddr .inputcss{
               width: 100%;
            }
            .cpN2_descr{
               padding: 0 15px 0 15px;
            }
            @media only screen and (max-width: 375px) {
               .buttonMReg{
                  margin-right: 0;
                  margin-bottom: 10px;
               }
               .buttonsMReg{
                  flex-flow: column wrap;
               }
            }
         }
      }
   }
}