*{
   font-family: "Titillium Web",Arial,sans-serif;
}

.thx_page{
   background-color: #002445;
   min-height: 100vh;
   display: flex;
   flex-flow: row wrap;
   justify-content: center;
   align-content: center;
   align-items: center;
}

.flex_col_thx{
   display: flex;
   flex-flow: column wrap;
   justify-content: center;
   align-content: center;
   align-items: center;
}

.thx_box{
   width: 880px;
   padding: 0 10px;
   min-height: 475px;
   border-radius: 30px;
   background-color: rgba(255, 255, 255, 0.52);
}

.social_icon_thx{
   color: #002445;
}

a{
   text-decoration: none;
}

.flex_row_social_icons{
   padding-top: 7px;
}

.icon_thx{
   padding: 0 10px 0 10px;
}

.light_text{
   font-weight: 300;
   text-align: center;
   font-size: 18px;
}

.thx_title{
   font-weight: 700;
   font-size: 22px;
}

.block_thx{
   padding: 10px 0 10px 0;
}

.grazie{
   margin-bottom: 10px;
}

@media only screen and (max-width: 965px) {
   .thx_box{
      margin: 0 20px;
   }
   
}