*{
  font-family: "Titillium Web",Arial,sans-serif;
  margin: 0;
  padding: 0;
}

a{
  text-decoration: none;
}

.pointer{
   cursor: pointer;
}

img{
  height: 100%; width: 100%;
}

.empty_button{
  width: 160px;
  height: 45px;
  border: 3px solid #002445;
  background-color: transparent;
  color: #002445;
  border-radius: 1000px;
  text-align: center;
  line-height: 45px;
  font-size: 14px;
  padding : 0 5px 0 5px;
}

.empty_button:hover{
  background-color: #002445;
  color: #FFFFFF;
}

.filled_button{
  width: 160px;
  height: 51px;
  background-color: #002445;
  color: #FFFFFF;
  border-radius: 1000px;
  text-align: center;
  line-height: 51px;
  font-size: 14px;
  padding : 0 5px 0 5px;
}

.filled_button:hover{
  opacity: 0.7;
}

#fbIcon:hover{
  color: #3B5998;
}

#lnIcon:hover{
  color: #007BB6;
}

#igIcon:hover{
  color: #8A3AB9;
}

#ytIcon:hover{
  color: #E52D27;
}