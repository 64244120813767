*{
   font-family: "Titillium Web",Arial,sans-serif;
}

.landing{
   background-color: #002445;
   padding: 0 30px 0 30px;
   min-height: 100vh;
}

.first_block{
   display: flex;
   flex-flow: row wrap;
   justify-content: space-between;
   align-content: center;
   align-items: center;
}

.second_block{
   display: flex;
   flex-flow: row wrap;
   justify-content: center;
   align-content: center;
   align-items: center;
}

.white_box{
   background-color: white;
   min-width: 50%;
   min-height: 480px;
   padding: 10px 20px 50px 20px;
   display: flex;
   flex-flow: column wrap;
   justify-content: center;
   align-content: center;
   align-items: center;
}

.cp_next_3Img{
   width: 475px;
}

.cp_next_3Descrption{
   font-size: 22px;
   font-weight: bold;
   text-align: center;
   max-width: 620px;
}

.login_box{
   min-width: 35%;
   background-color: rgba(255, 255, 255, 0.52);
   border-radius: 30px;
   min-width: 340px;
   min-height: 515px;
   margin-left: 60px;
   display: flex;
   flex-flow: column wrap;
   justify-content: flex-start;
   align-content: center;
   align-items: center;
   color: #002445;
   font-size: 14px;
}

.title{
   font-size: 18px;
   font-weight: bold;
   margin: 70px 0 20px 0;
}

.inputText{
   height: 25px;
   border: solid 1px #002445;
}

input{
   outline: none;
}

.elements{
   margin: 20px 0 20px 0;
}

.no_account{
   text-decoration: underline;
}

.display_error{
   color: rgb(185, 1, 1);
   text-align: center;
   line-height: 20px;
}

@media only screen and (max-width: 1120px) {
   .login_box{
      margin-left: 0px;
      /* margin-top: 30px;
      margin-bottom: 30px; */
   }

   .white_box{
      display: none;
   }
   
   @media only screen and (max-width: 380px) {
      .login_box{
         min-width: 320px;
      }
   }
}
