*{
   font-family: "Titillium Web",Arial,sans-serif;
}

.footer{
   background-color: #002445;
   padding: 0.1px 30px 0 30px;
   min-height: 500px;
}

.flex_row{
   display: flex;
   flex-flow: row wrap;
   justify-content: space-between;
   margin-top: 50px;
   color: #FFFFFF;
   font-size: 13px;
}

.flex_col_left{
   display: flex;
   flex-flow: column wrap;
   justify-content: center;
   align-content: center;
   align-items: flex-start;
}

.logo{
   height: 150px;
   width: auto;
}

.text{
   width: 640px;
   font-weight: 200;
   margin-bottom: 20px;
   line-height: 20px;
}

.mar_top{
   margin-top: -20px;
}

.bold_txt{
   font-weight: bolder;
}

.white_btn{
   width: 160px;
   height: 45px;
   border: 1px solid #FFFFFF;
   background-color: transparent;
   border-radius: 1000px;
   text-align: center;
   line-height: 45px;
   color: #FFFFFF;
}
 
.white_btn:hover{
   background-color: #FFFFFF;
   color: #002445;
}

sup{
   line-height: 0px;
}

.flex_col_right{
   display: flex;
   flex-flow: column wrap;
   justify-content: center;
   align-content: center;
   align-items: center;
}

.icons{
   fill: white;
   height: 32px;
   width: auto;
}

.fix_assistenza{
   margin-top: -6.5px;
}

.icon_assistance{
   fill: white;
   height: 50px;
   width: auto;
   margin-top: 2px;
   margin-right: -5px;
}

.flex_row_icons{
   display: flex;
   flex-flow: row wrap;
   justify-content: space-between;
   align-content: center;
   align-items: center;
   padding: 0 10px 0 10px;
}

.help_btn{
   width: 210px;
   height: 45px;
   border: 1px solid #FFFFFF;
   background-color: transparent;
   border-radius: 1000px;
   text-align: center;
   line-height: 45px;
   color: #FFFFFF;
}
 
.help_btn:hover{
   background-color: #FFFFFF;
   color: #002445;
}

.help_btn:hover .icon_assistance{
   fill: #002445;
}

.flex_row_social a{
   padding: 0 5px 0 5px;
}

.blocks{
   padding: 10px 0 10px 0;
}

.social_icon{
   color: white;
}

a{
   text-decoration: none;
}

@media only screen and (max-width: 911px) {
   .flex_row{
      justify-content: center;
   }
   .flex_col_left{
      align-items: center;
   }
   .text{
      text-align: center;
   }

   @media only screen and (max-width: 685px) {
      .text{
         width: fit-content;
      }
   }
}

